import { ref, onMounted, onUnmounted, computed,nextTick } from "vue";
const validHash = ["notice", "Journalism", "guide","download"];
export default function useFilter(){
    const visibilityRef = ref("notice");
    const onHashChange = () => {
      
        const hash = location.hash.replace(/#\/?/,"");
        if(validHash.indexOf(hash) >= 0){
            visibilityRef.value = hash
        }else{
            location.hash = "";
            visibilityRef.value = 'notice'
        }
       
    }
    onMounted(() => {
        const hash = location.hash.replace(/#\/?/,"");
        if(validHash.indexOf(hash) >= 0){
            visibilityRef.value = hash
        }else{
            location.hash = "";
            visibilityRef.value = 'notice'
        }
        window.addEventListener("hashchange",onHashChange);
    });
    onUnmounted(() => {
        window.removeEventListener("hashchange",onUnmounted);
    })
   return {
    visibilityRef
   }
}