<template>
  <div class="realimeInfo-container">
    <div class="realimeInfo-top">
      <img src="https://cosmos-static.oss-cn-shanghai.aliyuncs.com/ztofficialwebsite/pc/zixun.png" />
    </div>
    <div class="realimeInfo-bottom container-width" >
      <div class="realimeInfo-bottom-left">
        <ul>
          <li :class="{ active: visibilityRef == 'notice' }">
            <a href="#notice">公告</a>
          </li>
          <li :class="{ active: visibilityRef == 'Journalism' }">
            <a href="#Journalism">新闻</a>
          </li>
          <li :class="{ active: visibilityRef == 'guide' }">
            <a href="#guide">办事指南</a>
          </li>
          <li :class="{ active: visibilityRef == 'download' }">
            <a href="#download">资料下载</a>
          </li>
        </ul>
      </div>
      <div class="realimeInfo-bottom-right realimeInfo-bottom-righ">
        <template v-if="visibilityRef == 'notice'">
          <notice></notice>
        </template>
        <template v-else-if="visibilityRef == 'Journalism'">
          <journalism></journalism>
        </template>
        <template v-else-if="visibilityRef == 'guide'">
          <guide></guide>
        </template>
        <template v-else-if="visibilityRef == 'download'">
          <download></download>
        </template>
        
         <!-- <notice v-show="visibilityRef == 'notice'"></notice>

        <journalism v-show="visibilityRef == 'Journalism'"></journalism>

        <guide v-show="visibilityRef == 'guide'"></guide>

        <download v-show="visibilityRef == 'download'"></download> -->
      </div>
    </div>
  </div>
</template>

<script>
import useFilter from "./competition/useFilter";
import notice from "./Notice.vue";
import Journalism from "./Journalism.vue";
import guide from "./Guide.vue";
import download from "./Download.vue";
export default {
  components: {
    notice,
    Journalism,
    guide,
    download,
  },
  setup() {
    return {
      ...useFilter(),
    };
  },
};
</script>

<style scoped>
.realimeInfo-top {
  max-width: 1420px;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
}
.realimeInfo-top img {
  width: 100%;
}
.realimeInfo-bottom {
  display: flex;
  padding-top: 40px;
  padding-bottom: 40px;
  overflow-x: hidden;
  min-height: 300px;
}
.realimeInfo-bottom-left {
  width: 140px;
  height: 240px;
  background: rgba(242, 245, 248, 1);
  text-align: center;
  opacity: 1;
  font-size: 20px;
  color: #000000;
  margin-right: 50px;
}
@media screen and (max-width:1460px) {
  .realimeInfo-bottom-left {
     margin-left: 40px;
     margin-right: 40px;
  }


}
.realimeInfo-bottom-left li {
  width: 140px;
  height: 60px;
  line-height: 60px;
}
.realimeInfo-bottom-left li a {
  width: 140px;
  display: inline-block;
}
.active {
  color: #ffffff;
  background: linear-gradient(180deg, #000000 0%, #999999 100%);
}
.active a {
  color: #ffffff;
}
.realimeInfo-bottom-right{
  width: 90%;
}
@media screen and (max-width:1473px) {
  .realimeInfo-bottom-righ{
     /*padding: 0 50px;*/
      width: 80%;
     box-sizing: border-box;
  }

}
</style>
