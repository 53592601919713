<template>
  <div class="guide-container">
    <div class="notice-top" style="position: relative">
      <input class="input" placeholder="搜索关键字" v-model="waitSearch" />
      <img
        src="../../assets/images/xiaochazi.png"
        alt=""
        style="width: 18px; left: 300px; top: 10px; position: absolute"
        @click="clearInput"
        v-show="waitSearch !== ''"
      />
      <button class="btn" @click="waitSelectBtn" style="vertical-align: top">
        查询
      </button>
    </div>
    <div v-if="noticeList != null && noticeList.length>0">
      <div class="notice-con">
        <ul>
          <li v-for="item in noticeList" :key="item.id" @click="navBtn(item)">
            <div class="left-text">
              {{ item.title }}
            </div>
            <div>
              {{ item.uploadTime }}
            </div>
          </li>
        </ul>
      </div>
      <div class="demo-pagination-block">
        <Pagination :total="total" :page="pageS" key="guide" name='guide' v-show="noticeList !== null && noticeList.length > 0" />
      </div>
    </div>
    <div v-else class="no-date">
      暂无相关内容
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount,nextTick } from "vue";
import { fly, filedownloadUrl } from "@/requestUrl";
import Pagination from "@/components/Pagination.vue";
import mitt from "@/utils/mitt";
import { useRouter } from "vue-router";
export default {
  components: {
    Pagination,
  },
  setup() {
    const router = useRouter();
    const pageS = ref(1);
    if (sessionStorage.getItem("pageGuid")) {
      pageS.value = +sessionStorage.getItem("pageGuid");
    }
    const total = ref(10000000000);
    const pageSize = ref(10);
    const pageNo = ref(1);
    const noticeSearch = ref("");
    const noticeList = ref("");
    const waitSearch = ref("");
    onMounted(() => {
      selectBtn();
      const hash = location.hash.replace(/#\/?/, "");
      console.log();
      mitt.on(hash, guideBtn);
    });
    const guideBtn = (page) => {
        console.log(2);
        pageS.value = page;
        selectBtn();
      }
    onBeforeUnmount(() => {
      mitt.off("guide",guideBtn);
      const div = document.getElementsByClassName("realimeInfo-bottom")[0];
      const height = div.clientHeight;
      div.style.height = height + "px";
      nextTick(() => {
        div.style.height = "auto";
      });
    });
    const navBtn = function (item) {
      sessionStorage.setItem("pageGuid", pageS.value);
      console.log(item);
      router.push({
        path: "deailed",
        query: {
          it: JSON.stringify(item),
          name: "办事指南",
        },
      });
    };
    const waitSelectBtn = () => {
      noticeSearch.value = waitSearch.value;
      selectBtn();
    };
    const clearInput = () => {
      waitSearch.value = "";
      noticeSearch.value = "";
    };
    const selectBtn = () => {
      fly
        .post(filedownloadUrl, {
          regionCode:localStorage.getItem('regionCode'), //园区code 必填
          title: noticeSearch.value,
          pageNum: pageS.value, //分页，显示第1页的数据,这个数据也可以省略不写，默认是1
          pageSize: pageSize.value, //不写就行，有需求的话可以设置一页有几个数据，这里默认10个
          startTime: null, //非必填  筛选条件开始时间
          endTime: null, //非必填  筛选条件开始时间
        })
        .then((res) => {
          noticeList.value = res.data.data.list;
          total.value = res.data.data.total;
          console.log(res);
        });
    };
    return {
      noticeSearch,
      selectBtn,
      noticeList,
      pageS,
      total,
      navBtn,
      waitSearch,
      clearInput,
      waitSelectBtn,
    };
  },
};
</script>

<style scoped>
.notice-con {
  max-width: 1230px;
}
.notice-con ul {
  min-height: 300px;
}
.notice-con ul li {
  display: flex;
  justify-content: space-between;
  color: #555;
  font-size: 20px;
  font-weight: 400;
  position: relative;
  padding-left: 20px;
  margin-top: 12px;
}
.notice-con ul li div {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 40px;
  cursor: pointer;
}
.left-text {
  max-width: 995px;
}
li::after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  background: linear-gradient(180deg, #999999 0%, #000000 100%);
  border-radius: 50%;
  position: absolute;
  left: -0px;
  top: 14px;
}
</style>
